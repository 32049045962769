import Head from 'next/head';
import { withTranslation } from '../../../i18n';
import { decodeHtmlEntities } from '../../../utils/decodeHtmlEntities';
const { bf_app_id, destinations } = require('../../../constants');
import { isNews as isBuzzNews } from '../../../utils/isNews';
import appleTouchIcon from '../../../public/static/images/apple-touch-icon.png';
import bfFavicon from '../../../public/static/images/favicon.ico';
import newsFavicon from '../../../public/static/images/favicon-news.ico';


export default withTranslation('common')(({ buzz, t }) => {
  const { metadata: m, destination_name } = buzz;
  const { base_url, facebook_publisher } = destinations[destination_name];
  const isNews = isBuzzNews(buzz);
  const favicon = isNews ? newsFavicon : bfFavicon;

  return (
    <Head>
      <meta key="copyright" name="copyright" content={t('copyright_buzzfeed_inc')} />
      <meta key="apple-mobile-web-app-capable" name="apple-mobile-web-app-capable" content="yes" />
      <meta key="apple-mobile-web-app-title" name="apple-mobile-web-app-title" content="BuzzFeed" />
      <meta key="theme-color" name="theme-color" content="#ee3322" />
      {isNews && <link key="apple-touch-icon" rel="apple-touch-icon" href={appleTouchIcon} />}
      <link key="shortcut-icon" rel="shortcut icon" crossOrigin="" href={favicon} type="image/x-icon" />
      <link key="manifest" rel="manifest" crossOrigin="use-credentials" href="/manifest.json" />

      <meta key="title" name="title" content={m.title} />
      <meta key="description" name="description" content={decodeHtmlEntities(m.description)} />
      <meta key="bf:buzzid" property="bf:buzzid" content={m.buzzId} />
      <meta key="bf:userid" property="bf:userid" content={m.userId} />
      {/* Buzz */}
      <link key="canonical" rel="canonical" href={m.SEOCanonicalUrl} />
      {m.alternateLanguages.map((language, k) => (
        <link key={k} rel="alternate" href={m.canonicalUrl} hrefLang={language} />
      ))}
      {m.translationLinks.map((translation, k) => (
        <link key={`${translation}-${k}`} rel="alternate" href={`${base_url}${translation.url}`} hrefLang={translation.language} />
      ))}
      {m.translationLinks.filter(translation => !translation["isTranslation"])
                         .map((translation) => (
        <link key="default-language" rel="alternate" href={`${base_url}${translation.url}`} hrefLang="x-default" />
      ))}
      <link key="image_src" rel="image_src" href={m.imageSrc} />
      <meta key="robots" name="robots" content={m.robots} />
      <meta key="author" property="author" content={m.author} />
      {/* Pinterest */}
      <meta key="pin:media" property="pin:media" content={m.pinMedia} />
      <meta key="pin:description" property="pin:description" content={decodeHtmlEntities(m.pinDescription)} />
      {!!m.pinId && (
        <meta key="pin:id" property="pin:id" content={m.pinId} />
      )}
      {/* Facebook */}
      <meta key="fb:smart_publish:robots" property="fb:smart_publish:robots" content="noauto" />
      <meta key="fb:app_id" property="fb:app_id" content={m.fbAppId} />
      <meta property="al:ios:app_store_id" content={m.alIosAppStoreId} />
      <meta property="al:ios:app_name" content={m.alIosAppName} />
      <meta property="al:ios:url" content={m.alIosUrl} />
      <meta key="article:section" property="article:section" content={m.category} />
      <meta key="article:publisher" property="article:publisher" content={facebook_publisher} />
      <meta key="article:content_tier" property="article:content_tier" content="free" />
      <meta key="article:opinion" property="article:opinion" content={m.articleOpinion} />
      {!!m.authorFacebookPageURL && (
        <meta key="article:author" property="article:author" content={m.authorFacebookPageURL} />
      )}
      {m.tags.map(tag => (
        <meta key={`article:tag:${tag}`} property="article:tag" content={tag} />
      ))}
      {/* Open Graph */}
      <meta key="og:url" property="og:url" content={m.canonicalShareUrl} />
      <meta key="og:site_name" property="og:site_name" content={m.siteName} />
      {m.richPreview &&
        <meta key="og:image" property="og:image" content={m.ogImage} />
      }
      {m.richPreview &&
        <meta key="og:image:alt" property="og:image:alt" content={m.ogImageAlt} />
      }
      <meta key="og:title" property="og:title" content={decodeHtmlEntities(m.ogTitle)} />
      <meta key="og:description" property="og:description" content={decodeHtmlEntities(m.ogDescription)} />
      <meta key="og:type" property="og:type" content="article" />
      {/* Twitter */}
      {!isNews && (
        <>
          <meta key="twitter:app:id:iphone" name="twitter:app:id:iphone" content={bf_app_id} />
          <meta key="twitter:app:url:iphone" name="twitter:app:url:iphone" content={`buzzfeed://buzz${m.uri}`} />
          <meta key="twitter:app:id:ipad" name="twitter:app:id:ipad" content={bf_app_id} />
          <meta key="twitter:app:url:ipad" name="twitter:app:url:ipad" content={`buzzfeed://buzz${m.uri}`} />
          <meta key="twitter:app:id:googleplay" name="twitter:app:id:googleplay" content="com.buzzfeed.android" />
          <meta key="twitter:app:url:googleplay" name="twitter:app:url:googleplay" content={base_url + m.uri} />
        </>
      )}
      <meta key="twitter:site" name="twitter:site" content={m.twitterSiteAccount} />
      <meta key="twitter:creator" name="twitter:creator" content={m.twitterAuthorAccount} />
      <meta key="twitter:url" name="twitter:url" content={base_url + m.uri} />
      <meta key="twitter:title" name="twitter:title" content={decodeHtmlEntities(m.twitterTitle)} />
      <meta key="twitter:description" name="twitter:description" content={decodeHtmlEntities(m.twitterDescription)} />
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      {m.richPreview &&
        <meta key="twitter:image" name="twitter:image" content={m.twitterMedia || m.imageSrc} />
      }

      {/* Yahoo Japan/Taboola */}
      {buzz.classification.edition === 'Japan' && (
        <>
          <meta key="category" name="category" content={m.category} />
          <meta key="keywords" name="keywords" content={m.tags.join(',')} />
          <meta key="epoch-publish-date-seconds" name="epoch-publish-date-seconds" content={m.published} />
        </>
      )}
      {/* Outbrain */}
      <meta key="articleSection" itemProp="articleSection" content={m.edition} />
    </Head>
  );
})
