import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import ErrorPage from 'next/error';
import { ErrorBoundary } from '@buzzfeed/react-components';
import { CLUSTER } from '../constants';
import setResponseHeaders from '@buzzfeed/headers';
import { getCustomSurrogateKeys } from '../cache/fastly';
import { captureException } from '@sentry/nextjs';
import i18n from '../i18n';
import Head from './_head';
import { trackClientPerformanceMetric } from '../hooks/analytics/client-event-tracking';
import '../styles/main.scss';
import '../styles/ads.scss';
require.context('../public/static/locales', true, /\.json$/);

// local development - a11y logging
import '../utils/react-axe-setup';

/**
 * Next.js custom metrics will be collected. Web Vitals metrics will continue to be collected by the
 * `@buzzfeed/performance` library instead as it also instruments defines and instruments it's own
 * set of additional custom performance metrics.
 */
export function reportWebVitals(metric) {
  /**
   * `reportWebVitals` does not have access to page props by default. In order to provide analytics
   * calls with contextual data, the `getDerivedStateFromProps` method sets the value of pageProps
   * as one of the properties of this function.
   */
  const buzz = reportWebVitals?.pageProps?.buzz;

  if (buzz && metric.label === 'custom') {
    const idleCallback = () => {
      trackClientPerformanceMetric(buzz, {
        metric_name: metric.name,
        metric_type: 'custom',
        metric_value: metric.value,
      });
    };

    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(idleCallback);
    } else {
      setTimeout(idleCallback, 1);
    }
  }
}

class Application extends App {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};

      /**
       * On client side when pager switches pages within app - this gets called before router
       *  document has registered location change.  Hook into this to update referrer, to avoid
       *  referrer always being whatever the first page hit was
       * Per NextJS - if wanting to know client side history for referrer without actually navigating
       *  back - need to track ourselves: https://github.com/zeit/next.js/issues/3565
       */
      if (!ctx.req) {
        window.clientEventTracking_referrer = document.location.href;
      }

      if (Component.getInitialProps) {
        pageProps = (await Component.getInitialProps(ctx)) || {};
      }

      if (ctx.res) {
        // catching errors here becuase bpage slug route is catching
        // upstream requests from the client and tryihng to make bpages : )
        if (CLUSTER === 'dev') {
          if (ctx.res.statusCode > 399 && ctx.res.statusCode < 500) {
            return { pageProps: { namespacesRequired: [], statusCode: 404 } };
          }
        }
        if (pageProps.statusCode) {
          ctx.res.statusCode = pageProps.statusCode;
        }
        if (ctx.res.statusCode !== 301) {
          if (pageProps.buzz) {
            setResponseHeaders({
              req: ctx.req,
              res: ctx.res,
              maxAge: pageProps.buzz.isPreview ? 0 : 86400,
              edition: pageProps.buzz.country_code,
              contentIDs: [
                { type: 'buzz', id: pageProps.buzz.id },
                { type: 'user', id: pageProps.buzz.username}
              ],
              customKeys: getCustomSurrogateKeys(pageProps.buzz)
            })
          } else {
            setResponseHeaders({ req: ctx.req, res: ctx.res })
          }

        }
      } else if (CLUSTER !== 'dev' && pageProps.statusCode) {
        // When we have Client Side navigation errors we still want to be able to show
        // our custom 400/500 error pages. In order to this we can force a server side retry
        // which might succeed but if not will trigger the error pages to be loaded from the CDN
        Router.replace(ctx.asPath);
      }

      pageProps.namespacesRequired = ['common'];

      return { pageProps };
    } catch (err) {
      console.error(err);
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(err, ctx);

      return {
        pageProps: { namespacesRequired: [''] },
        hasError: true,
        errorEventId,
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    /**
     * Save a copy of `props.pageProps` on to the `reportWebVitals` function as it requires contextual data
     * to include in analytic requests. This function gets called before `reportWebVitals`.
     */
    if (typeof window !== undefined && props.pageProps) {
      reportWebVitals.pageProps = props.pageProps;
    }

    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    // reset html lang attribute on client-side navigation
    const { pageProps: prevPageProps = {} } = prevProps;
    const { pageProps = {} } = this.props;
    const { buzz: prevBuzz = { language: 'en' } } = prevPageProps;
    const { buzz = { language: 'en' } } = pageProps;

    if (prevBuzz.language !== buzz.language) {
      const htmlRoot = document.getElementsByTagName('html');
      if (htmlRoot && htmlRoot[0] && htmlRoot[0].setAttribute) {
        htmlRoot[0].setAttribute('lang', buzz.language);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextState.hasError;
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  onError(error, errorInfo) {
    captureException(error, {
      level: 'fatal',
      extra: errorInfo,
      tags: {
        error_boundary: 'application'
      }
    });
  }

  render() {
    const { Component, pageProps, router } = this.props;

    if (pageProps.statusCode) {
      if (CLUSTER === 'dev' && pageProps.statusCode !== 404) {
        throw new Error(pageProps.error);
      }
      return <ErrorPage statusCode={pageProps.statusCode} />;
    }

    return (
      <ErrorBoundary
        fallbackRender={() => (
          <ErrorPage
            statusCode={500}
            title="An unexpected error has occurred"
          />
        )}
        onError={this.onError}
      >
        <Head {...pageProps} query={router.query} />
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  }
}

export default i18n.appWithTranslation(Application);
