import { getEdition, getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';

export function localization(buzz) {
  const edition = getEdition({
    location: buzz.location,
    country: buzz.country_code,
  });
  const [language, country] = edition.split('-');
  return {
    edition,
    language,
    country,
  };
}

// For use in ads and other contexts where we want the user's context, not the article context
export function userLocalization() {
  const edition = getEdition();
  const [language, country] = edition.split('-');
  return {
    edition,
    language,
    country,
    locale:
      language && country ? `${language}_${country.toUpperCase()}` : language,
  };
}

export function getUserGeo() {
  return getUserGeoCountry();
}
