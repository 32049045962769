const hash = require('fnv1a');
const { settings } = require('@buzzfeed/rig');

const N_OF_BUCKETS = 10;

const bfpKeys = bfpFormats => {
  if (!bfpFormats.length) {
    return [];
  }
  const cluster = settings.get('cluster');
  return [
    `bfp-${cluster}`,
    ...bfpFormats.map(format => `bfp-${format}-${cluster}`),
  ];
};

const bucketKeys = word => {
  const bucket = hash(word) % N_OF_BUCKETS;
  const commit = settings.get('rig_image_version').toLowerCase();
  const service = settings.get('service');
  return [
    `${service}-bucket-${bucket}`,
    `${service}-bucket-${bucket}__${service}-commit-${commit}`,
  ];
};

const commentsKeys = buzz => {
  if (buzz.isExplicitCommentsPage) {
    const service = settings.get('service');
    const cluster = settings.get('cluster');
    return [
      `${service}-${cluster}-comments`,
      `comments-${buzz.id}`,
    ];
  }
  return [];
}

/**
 * Generates list of surrogate keys to use for a CDN cache entry
 * @param {Object} params
 * @param {Object} params.buzz - buzz data object
 */
const getCustomSurrogateKeys = (buzz) => {
  return [
    ...bfpKeys(buzz.bfpFormats),
    ...bucketKeys(buzz.uri),
    ...commentsKeys(buzz),
  ];
}

module.exports = {
  getCustomSurrogateKeys,
};
