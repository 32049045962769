/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import InlineScripts from '../components/Head/InlineScripts';
import Metadata from '../components/Head/Metadata';
import MetadataCommentsPage from '../components/Head/MetadataCommentsPage';
import PreconnectDnsPrefetch from '../components/Head/PreconnectDnsPrefetch';
import { PageLevelSchemaOrg } from '../components/Head/SchemaOrg';
import SubbuzzFontLoader from '../components/Head/SubuzzFontLoader';
import { isNews } from '../utils/isNews';

/**
 * When adding a new third-party scipt, consider using some of the various techniques
 * for lowering its network/execution priority when appropriate
 * so as to minimize the negative effect on performance
 * @see https://addyosmani.com/blog/script-priorities/
 */
const CustomHead = ({ buzz, badges, subbuzzData }) => {
  const { isCommentsPage, isExplicitCommentsPage } = buzz;
  const assets_js = !isCommentsPage && subbuzzData.assets && subbuzzData.assets.js ? subbuzzData.assets.js : [];

  /**
   * If possible, this is the preferred order based on research by Harry Roberts (csswizardry.com).
   *   1) <meta charset />
   *   2) <meta name="viewport" />
   *   3) <base> - Probably not needed but would place between here
   *   4) <title>
   *     - Placing close to the top helps display page title on browser tab sooner. If placed after
   *       blocking scripts, a slow  connection/computer might not see a title on the tab until
   *       scripts finish blocking.
   *   5) preconnect / dns-prefetch fallback
   *   6) <script></script> - Inline JS
   *   7) <script src=""></script>
   *   8) <link rel="stylesheet" />
   *   9) <style> - inline css </style>
   *  10) preload - place here due to bug in chrome
   *  11) <script src="" (async|defer)></script>
   *  12) prefetch / prerender - Files that might be needed later. Deprioritized.
   *  13) Everything else (icons, Open Graph, etc.)
   */
  return (
    <>
      {/* charset / viewport / base / title */}
      <Head>
        <meta key="charset" charSet="utf-8" />
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
        <title>{isExplicitCommentsPage ? 'Comments on ' : ''}{buzz.metadata.title}</title>
      </Head>

      {/**
       * preconnect -  for the most important/critical assets
       * dns-prefetch - as a fallback for preconnect and for all other third party origins
       */}
      <PreconnectDnsPrefetch buzz={buzz} />

      {/* Inline JS */}
      <InlineScripts buzz={buzz} badges={badges} />
      <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />

      {/* External stylesheets (<link rel="stylesheet" />) */}
      <Head>
        {/**
          * Typekit fonts are used on buzzfeednews. Adobe provides a stylesheet that load
          * additional stylesheets using multiple @import calls. This is bad for performance as
          * those stylehseets are render blocking. The following stylesheet is used for tracking
          * (like an image pixel) and returns an empty response. It blocks the critical path for
          * nearly 2 seconds on a fast 3G connection. Instead of removing it altogther and risk
          * violating thier terms of service, the stylesheet is asynchronously loaded here with low
          * priority.
          * @see https://helpx.adobe.com/fonts/using/add-fonts-website.html
          * @see https://csswizardry.com/2018/11/css-and-network-performance/#avoid-import-in-css-files
          * @see https://www.filamentgroup.com/lab/load-css-simpler/
          */}
        {isNews(buzz) && (
          <link
            rel="stylesheet"
            media="print"
            onLoad="this.onload=null;this.removeAttribute('media');"
            href="https://p.typekit.net/p.css?s=1&k=pcr5jdf&ht=tk&f=10326&a=51127441&app=typekit&e=css"
          />
        )}
      </Head>

      {/* preload */}
      <Head>
        {/* OneTrust CMP */}
        <link rel="preload" crossOrigin="anonymous" href="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" as="script" />

        {assets_js.map((src, i) => <link rel="preload" href={src} as="script" key={`subbuzz-preload-js-${i}`} />)}

        {!buzz.disableAds &&
          <>
            <link rel="preload" href="https://securepubads.g.doubleclick.net/tag/js/gpt.js" as="script" />
          </>
        }
      </Head>
      {!isCommentsPage && <SubbuzzFontLoader buzz={buzz} />}

      {/* Async or deferred scripts go here `<script src="" async|defer></script>` */}

      {/* Everything else ((icons, Open Graph, etc.) */}
      {isCommentsPage ?
        <MetadataCommentsPage buzz={buzz} /> :
        <Metadata buzz={buzz} />
      }
      <PageLevelSchemaOrg buzz={buzz} />
    </>
  );
};

export default CustomHead;
