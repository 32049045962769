import {
  createClientEvent,
  CONTEXT_PAGE_TYPES,
} from '@buzzfeed/client-event-tracking';

import {
  ab_test,
  addressability,
  content_action,
  external_link,
  impression,
  internal_link,
  pageview,
  quiz_answer,
  quiz_complete,
  scroll,
  subbuzz_impression,
  videoview,
  time_spent,
} from '@buzzfeed/client-event-tracking/events';

import {
  createClientClickHandler,
  createClientImpressionHandler,
  createClientTimeSpentHandler,
} from '@buzzfeed/client-event-tracking/handlers';
import {
  createPerformanceEvent,
  trackPerformance,
} from '@buzzfeed/performance';

import { CLUSTER } from '../../../constants';
import { isNews } from '../../../utils/isNews';

const sendClientEvent = createClientEvent({ env: CLUSTER });

const sendPerformanceMetricEvent = createPerformanceEvent(sendClientEvent, {
  flush: false,
  type: 'web_performance_metric',
});

export const buzzCommonLayerData = buzz => () => {
  return {
    source: isNews(buzz) ? 'web_news' : 'web_bf',
    context_page_id: buzz.id,
    context_page_type: buzz.isExplicitCommentsPage ? 'comments' : CONTEXT_PAGE_TYPES.BUZZ,
    destination: buzz.destination_name,
    page_edition: buzz.country_code,
    referrer_uri: window.clientEventTracking_referrer || document.referrer,
  }
};

const createClientEventWithBuzz = event_config => (buzz, ...layers) => {
  sendClientEvent(event_config, buzzCommonLayerData(buzz), ...layers);
};

export const trackClientAddressability = createClientEventWithBuzz(addressability);

export const trackClientPageview = createClientEventWithBuzz(pageview);

export const trackClientTimeSpent = createClientEventWithBuzz(time_spent);

export const trackClientScrollDepth = createClientEventWithBuzz(scroll);

export const trackClientContentAction = createClientEventWithBuzz(
  content_action
);

export const trackClientQuizAnswer = createClientEventWithBuzz(quiz_answer);

export const trackClientQuizComplete = createClientEventWithBuzz(quiz_complete);

export const trackClientExperimentActive = createClientEventWithBuzz(ab_test);

export const trackClientVideoView = createClientEventWithBuzz(videoview);

export const trackClientInternalLink = createClientEventWithBuzz(internal_link);

export const trackClientExternalLink = createClientEventWithBuzz(external_link);

export const trackClientImpression = createClientEventWithBuzz(impression);

export const trackClientSubbuzzImpression = createClientEventWithBuzz(
  subbuzz_impression
);

export const attachClientInternalLinkHandler = createClientClickHandler(
  ({ layers }, ...additional_args) => {
    trackClientInternalLink(...additional_args, ...layers);
  }
);

export const attachClientExternalLinkHandler = createClientClickHandler(
  ({ layers }, ...additional_args) => {
    trackClientExternalLink(...additional_args, ...layers);
  }
);

export const attachClientContentAction = createClientClickHandler(
  ({ layers }, ...additional_args) => {
    trackClientContentAction(...additional_args, ...layers);
  }
);

export const attachClientImpressionHandler = createClientImpressionHandler(
  trackClientImpression
);

export const attachClientSubbuzzImpressionHandler = createClientImpressionHandler(
  trackClientSubbuzzImpression,
  /**
   * Fire if at least 1% viewable for 1 second (1 second is the default value for the
   * `visibility_time` option).
   */
  {
    threshold: 0.01,
  }
);

export const attachClientTimeSpentHandler = createClientTimeSpentHandler(
  ({ layers }, ...additional_args) => {
    trackClientTimeSpent(...additional_args, ...layers);
  }
);

export const trackClientPerformance = (buzz, ...layers) => {
  trackPerformance(sendClientEvent, {
    layers: [buzzCommonLayerData(buzz), ...layers],
    // we use a higher sample rate for BFN due to sparser traffic patterns
    sample_rate: buzz.destination_name === 'buzzfeed_news' ? 0.5 : 0.1,
  });
};

export const trackClientPerformanceMetric = (buzz, ...layers) => {
  sendPerformanceMetricEvent(buzzCommonLayerData(buzz), ...layers);
};
