import { Fragment } from 'react';
import Head from 'next/head';

function getFontsFromSubbuzzes(subbuzzes) {
  let fontsToLoad = {};

  subbuzzes.forEach(subbuzz => {
    if (subbuzz.style) {
      fontsToLoad = addFont(subbuzz.style, fontsToLoad);
    }

    if (subbuzz.form === 'quiz') {
      subbuzz.questions.forEach(question => {
        if (question.tile_metadata) {
          fontsToLoad = addFont(question.tile_metadata.tileStyles, fontsToLoad);
        }

        question.answers.forEach(answer => {
          if (answer.tile_metadata) {
            fontsToLoad = addFont(answer.tile_metadata.tileStyles, fontsToLoad);
          }
        });
      });
    }
  });

  return Object.keys(fontsToLoad).map(key => {
    return `${key}:${fontsToLoad[key].join(',')}`;
  });
}

function addFont(style, fontsToLoad) {
  const fontFamily = style['font_family'] || style['fontFamily'];

  if (
    fontFamily &&
    !fontFamily.includes('Proxima Nova') &&
    !fontFamily.includes('ProximaNova')
  ) {
    const fontWeight = style['font-weight'] || '400,700';

    if (!fontsToLoad[fontFamily]) {
      fontsToLoad[fontFamily] = [fontWeight];
    } else if (!fontsToLoad[fontFamily].includes(fontWeight)) {
      fontsToLoad[fontFamily] = fontsToLoad[fontFamily].push(fontWeight);
    }
  }
  return fontsToLoad;
}

function SubbuzzFontLoader({ buzz }) {
  const fonts = getFontsFromSubbuzzes(buzz.sub_buzzes);

  return (
    <Head>
      {fonts.length > 0 && (
        <>
          <link
            rel="dns-prefetch"
            href="//fonts.googleapis.com"
            key="prefetch-google-fonts"
          />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com/"
            crossOrigin="true"
            key="preconnect-google-fonts"
          />
        </>
      )}
      {fonts.map((font, i) => (
        <Fragment key={i}>
          <link
            rel="preload"
            href={`https://fonts.googleapis.com/css?family=${font}&display=swap`}
            as="style"
          />
          <link
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css?family=${font}&display=swap`}
          />
        </Fragment>
      ))}
    </Head>
  );
}

export default SubbuzzFontLoader;
