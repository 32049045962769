/**
 * ⚠️ these will be exposed on the client ⚠️
 */

const { settings } = require('@buzzfeed/rig');
const QUIZ_CATEGORIES = require('/app/lib/shared/quizzes-config/en-us/categories.json');
const QUIZ_TYPES = require('/app/lib/shared/quizzes-config/en-us/types.json');

const NODE_ENV = process.env.NODE_ENV || 'production';
const envConfig = {
  ASSET_PREFIX: '',
  NODE_ENV,
  QUIZ_CATEGORIES,
  QUIZ_TYPES,
};

if (NODE_ENV === 'production') {
  envConfig.ASSET_PREFIX = `/static-assets`;
}

try {
  const CLUSTER = settings.get('cluster');
  const RIG_DEPLOYMENT_TYPE = settings.get('rig_deployment_type');
  const client = settings.get('client');

  if (NODE_ENV === 'production' && CLUSTER === 'dev') {
    envConfig.ASSET_PREFIX = ``;
  }

  envConfig.CLUSTER = CLUSTER;
  envConfig.RIG_DEPLOYMENT_TYPE = RIG_DEPLOYMENT_TYPE;
  Object.assign(envConfig, client);
} catch (e) {}

module.exports = envConfig;
