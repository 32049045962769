/**
 * Based off of lodash's _unescape, taking only what is needed for our use case.
 * This keeps it light and as simple as possible.
 */

/** Used to map HTML entities to characters. */
const html_entities = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
  '&#34;': '"',
  '&#x27;': "'",
};

/** Used to match HTML entities and HTML characters. */
const re_html_entities = /&(?:amp|lt|gt|#34|#39|quot|#x27);/g;

/**
 * this method only converts the HTML entities, `&amp;`, `&lt;`, `&gt;`, `&#34;`, `&quot;`, `&#x27;` and `&#39;` in
 * `input` to their corresponding characters.
 */
const decodeHtmlEntities = input => {
  if (typeof input !== 'string') {
    return input;
  }

  return input.replace(
    re_html_entities,
    match => html_entities[match] || match
  );
};

module.exports = {
  decodeHtmlEntities,
};
