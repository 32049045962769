/**
 * Extracts all subbuzz products data into one arrray
 */
export function getAllProductsData(buzz = {}) {
  let productsData = [];

  if (buzz.sub_buzzes) {

    // Filter subbuzzes w/o products data
    productsData = buzz.sub_buzzes.filter(sb => {
      return sb.products;
    });

    // flatten arrays
    productsData = [].concat(...productsData.map(sb => {
      return sb.products;
    }));
  }

  return productsData;
}


/**
 * Returns product data of subbuzz by subbuzz id
 */
export function getProductsBySubbuzzId(buzz = {}, subbuzzId) {
  const subbuzz = buzz.sub_buzzes.find(sb => {
    return sb.id === subbuzzId;
  });

  return subbuzz.products || [];
}
