import getConfig from 'next/config';

const {
  CLUSTER,
  RIG_DEPLOYMENT_TYPE,
  ENV,
  QUIZ_CATEGORIES,
  QUIZ_TYPES,
  abeagle_host,
  bf_app_id,
  destinations,
  feature_flags,
  feeds_api_origin,
  image_service_url,
  link_conversion,
  mango_url,
  outbrain_ids,
  pressboard_usernames,
  recsys_api_origin,
  sentry_dsn: sentryDsn,
  site_captcha_key,
  site_component_api_origin,
  snapchat_tracking_id,
  social
} = getConfig().publicRuntimeConfig;

export {
  CLUSTER,
  RIG_DEPLOYMENT_TYPE,
  ENV,
  QUIZ_CATEGORIES,
  QUIZ_TYPES,
  abeagle_host,
  bf_app_id,
  destinations,
  feeds_api_origin,
  feature_flags,
  image_service_url,
  link_conversion,
  mango_url,
  outbrain_ids,
  pressboard_usernames,
  recsys_api_origin,
  sentryDsn,
  site_captcha_key,
  site_component_api_origin,
  snapchat_tracking_id,
  social
};
