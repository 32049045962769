import Head from 'next/head';
import { withTranslation } from '../../../i18n';
import { decodeHtmlEntities } from '../../../utils/decodeHtmlEntities';
import { isNews as isBuzzNews } from '../../../utils/isNews';
import appleTouchIcon from '../../../public/static/images/apple-touch-icon.png';
import bfFavicon from '../../../public/static/images/favicon.ico';
import newsFavicon from '../../../public/static/images/favicon-news.ico';

/**
 * Metadata for both comments-only pages (bpage with "/comments" at end of URL,
 * AND for discussion pages (bpage with user "discuss")
 */

export default withTranslation('common')(({ buzz, t }) => {
  const { metadata: m, isExplicitCommentsPage } = buzz;
  const isNews = isBuzzNews(buzz);
  const favicon = isNews ? newsFavicon : bfFavicon;

  const title = `${isExplicitCommentsPage ? 'Comments on ' : ''}${decodeHtmlEntities(buzz.title_text || m.title)}`;

  const canonicalUrl = isExplicitCommentsPage ? `${buzz.canonical_url}/comments` : buzz.canonical_url;
  const description = decodeHtmlEntities(buzz.description_text || buzz.description);

  return (
    <Head>
      <meta key="copyright" name="copyright" content={t('copyright_buzzfeed_inc')} />
      <meta key="apple-mobile-web-app-capable" name="apple-mobile-web-app-capable" content="yes" />
      <meta key="apple-mobile-web-app-title" name="apple-mobile-web-app-title" content="BuzzFeed" />
      <meta key="theme-color" name="theme-color" content="#ee3322" />
      {isNews && <link key="apple-touch-icon" rel="apple-touch-icon" href={appleTouchIcon} />}
      <link key="shortcut-icon" rel="shortcut icon" crossOrigin="" href={favicon} type="image/x-icon" />
      <link key="manifest" rel="manifest" crossOrigin="use-credentials" href="/manifest.json" />
      <meta name="referrer" content="unsafe-url" />

      <meta key="title" name="title" content={title} />
      <meta key="description" name="description" content={description} />
      {/* Buzz */}
      <link key="canonical" rel="canonical" href={`${m.SEOCanonicalUrl}/comments`} />
      <link key="image_src" rel="image_src" href={m.imageSrc} />
      <meta key="robots" name="robots" content="noindex, noimageindex" />
      <meta key="author" property="author" content={m.author} />
      {/* Facebook */}
      <meta key="fb:smart_publish:robots" property="fb:smart_publish:robots" content="noauto" />
      <meta key="fb:app_id" property="fb:app_id" content={m.fbAppId} />
      {/* Open Graph */}
      <meta key="og:url" property="og:url" content={canonicalUrl} />
      <meta key="og:site_name" property="og:site_name" content={m.siteName} />
      {m.richPreview &&
        <meta key="og:image" property="og:image" content={m.ogImage} />
      }
      {m.richPreview &&
        <meta key="og:image:alt" property="og:image:alt" content={m.ogImageAlt} />
      }
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:description" property="og:description" content={description} />
      {/* Twitter */}
      <meta key="twitter:site" name="twitter:site" content={m.twitterSiteAccount} />
      <meta key="twitter:creator" name="twitter:creator" content={m.twitterAuthorAccount} />
      <meta key="twitter:url" name="twitter:url" content={canonicalUrl} />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:description" name="twitter:description" content={description} />
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      {m.richPreview &&
        <meta key="twitter:image" name="twitter:image" content={m.twitterMedia || m.imageSrc} />
      }
      {/* CMS Tool extension integration - is not relevent for comments only */}
      {!isExplicitCommentsPage &&
        <>
          <meta key="bf:buzzid" property="bf:buzzid" content={m.buzzId} />
          <meta key="bf:userid" property="bf:userid" content={m.userId} />
       </>
      }

    </Head>
  );
})
