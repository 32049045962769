/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  loadAbandonmentAsString,
  jsProfilerAsString,
} from '@buzzfeed/performance';
import striptags from 'striptags';
import { isServer } from '../../../utils/isServer';

import {
  destinations,
} from '../../../constants';
import { adsContextAsString } from '@buzzfeed/adlib/bindings/react/utils.js';
import { APSTAG_SNIPPET } from '@buzzfeed/adlib/bindings/react/constants/snippets.js';
import { getAdCallCategory } from '@buzzfeed/adlib/dist/module/services/ad-call-utils';
import { getPoe } from '@buzzfeed/adlib/dist/module/services/page-targeting';
import { getBuzzTags } from '../../../utils/ads/context.js';
import { getUserGeo } from '../../../utils/localization';


const WINDOW_GLOBALS = `
  window.BZFD = {
    Config: {
      bfwInfoCookie: 'bf2-b_info'
    },
    Context: { page: {} }
  };
`;

const CNX_SNIPPET = `
  if (!window.cnx) {
    (window.cnx = {}), (window.cnx.cmd = []);
    var t = document.createElement('iframe');
    (t.src='javascript:false'),
    (t.display = 'none'),
      (t.onload = function () {
        var n = t.contentWindow.document,
          c = n.createElement('script');
        (c.src = '//cd.connatix.com/connatix.player.js?cid=1bf35e8b-3d55-48a6-aef8-0ebfdd277df7'),
          c.setAttribute('async', '1'),
          c.setAttribute('type', 'text/javascript'),
          n.body.appendChild(c);
      }),
      document.head.appendChild(t);
  }
`;

/* GOOGLE TAG MANAGER */
const GTM_IDS = {
  'default': 'GTM-55L29GF',
  'Japan': 'GTM-WQ22LP4',
  'Australia': 'GTM-5V84PMGM',
  'United Kingdom': 'GTM-NB4CB4MD'
};

function getGTMSnippet(edition, buzz) {
  if (isServer()) {
    return '';
  }

  const userGeo = getUserGeo();
  let GTM_ID = GTM_IDS['default'];
  let dataLayerCode = '';

  if (userGeo === 'JP') {
    GTM_ID = GTM_IDS['Japan'];
  } else if (userGeo === 'AU' || userGeo === 'NZ') {
    GTM_ID = GTM_IDS['Australia'];
    dataLayerCode = `
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'articleSection': '${buzz.classification.section}',
      'keywords': '${buzz.metadata.tags.join(', ')}',
      'pageType': '${buzz.isExplicitCommentsPage ? 'comments' : 'buzz'}',
      'pagePath': '${buzz.canonical_path}'
    });`;
  } else if (userGeo === 'GB' || userGeo === 'IE') {
    GTM_ID = GTM_IDS['United Kingdom'];
  }

  return `
  ${dataLayerCode}
  (function(w,d,s,l,i){
    w[l] = w[l]||[];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l!='dataLayer'?'&l='+l:'';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j,f);
  })(window, document, 'script', 'dataLayer', '${GTM_ID}');`;
}

// @todo - have this log to sentry so we get feedback on BFA calls?
const BFA_DUMMY = `
  window.bfa = function() { };
`;

function getPoeData() {
  if (isServer()) {
    return '';
  }
  return getPoe({
    env: {
      isBPage: true,
    }
  });
}

/**
 * When adding a new third-party scipt, consider using some of the various techniques
 * for lowering its network/execution priority when appropriate
 * so as to minimize the negative effect on performance
 * @see https://addyosmani.com/blog/script-priorities/
 */
const InlineScripts = ({ buzz, badges }) => {
  const router = useRouter();

  const isExplicitCommentsPage = buzz.isExplicitCommentsPage;

  return (
    <>
      <Head>
        {/* Inline JS */}
        <script
          key="load-abandonment"
          dangerouslySetInnerHTML={{
            __html: loadAbandonmentAsString({
              context_page_id: buzz.id,
              context_page_type: isExplicitCommentsPage ? 'comments' : 'buzz',
              destination: buzz.destination_name,
              page_edition: buzz.country_code,
            }),
          }}
        />
        <script
          key="js-profiling"
          dangerouslySetInnerHTML={{ __html: jsProfilerAsString() }}
        />
        <script key="window-globals" dangerouslySetInnerHTML={{ __html: WINDOW_GLOBALS }} />
        <script
          key="ads-context"
          dangerouslySetInnerHTML={{
            __html: adsContextAsString({
              badges: badges.map(badge => badge.badge_type),
              cms_tags: getBuzzTags(buzz),
              dfp_keyword: striptags(router.query['dfp-keyword']) || null,
              id: buzz.id,
              section: buzz.all_classifications.sections,
              zone3: getAdCallCategory({}, { env: {
                pageSection: buzz.classification.section,
                pageCategory: buzz.category,
                allPageSections: buzz.all_classifications.sections,
              }}),
              isTasty: false,
              pagetype: 'B',
              poe: getPoeData(),
            }),
          }}
        />
        <script key="bfa-dummy" dangerouslySetInnerHTML={{ __html: BFA_DUMMY }} />
        {/* APSTAG SNIPPET */}
        <script key="apstag" dangerouslySetInnerHTML={{ __html: APSTAG_SNIPPET  }} />
        {/* CONNATIX SNIPPET */}
        <script dangerouslySetInnerHTML={{ __html: CNX_SNIPPET }} key="connatix-snippet" />
        {/* Google Tag Manager (SEO) */}
        {destinations[buzz.destination_name].gtm_enabled &&
          <script dangerouslySetInnerHTML={{ __html: getGTMSnippet(buzz.classification.edition, buzz) }} key="google-tag-manager" />
        }
      </Head>
    </>
  );
};

export default InlineScripts;
