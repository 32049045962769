import Head from 'next/head';
import {
  destinations,
  social,
  image_service_url as imageServiceUrl,
} from '../../../constants/index';
import { getAllProductsData } from '../../../utils/productsData';

const formatUnixDate = date => {
  if (!date) {
    return '';
  }
  let d = new Date(Number(date) * 1000);
  return d.toISOString();
};

const getBylines = buzz => {
  const { bylines, destination_name } = buzz;
  const { base_url } = destinations[destination_name];
  let metadata = bylines.map(byline => ({
    '@type': 'Person',
    name: byline.display_name,
    url: `${base_url}/${byline.username}`,
    jobTitle: byline.title,
  }));
  return metadata;
};

const logo = {
  '@type': 'ImageObject',
  url: `${imageServiceUrl}/static/images/amp/buzzfeed-60px-high.png`,
  width: 341,
  height: 60,
};

const getPageSchema = buzz => {
  const type = buzz.isShopping ? 'Article' : 'NewsArticle';
  const tags = typeof buzz.tags === 'object' ? buzz.tags.filter(tag => !tag.includes('--')) : [];
  const { publisher_name } = destinations[buzz.destination_name];
  let imageURL = buzz.picture;
  if (imageURL) {
    imageURL = `${imageURL}${imageURL.includes('?') ? '&' : '?'}resize=1200:*`;
  }
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': type,
    isAccessibleForFree: true,
    mainEntityOfPage: buzz.canonical_url,
    description: buzz.description_text,
    headline: buzz.metadata.title,
    datePublished: formatUnixDate(buzz.published),
    dateModified: formatUnixDate(buzz.edit_updated),
    author: getBylines(buzz),
    articleSection: buzz?.classification?.section,
    keywords: tags,
    publisher: {
      '@type': 'Organization',
      name: publisher_name,
      url: destinations[buzz.destination_name].base_url,
      sameAs: [
        social.twitter,
        social.facebook,
        social.pinterest,
        social.youtube,
      ],
      logo,
    },
    image: {
      '@type': 'ImageObject',
      url: imageURL,
      width: 1200,
      representativeOfPage: true,
    },
  });
};

const getOrgSchema = buzz => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'BuzzFeed',
    url: destinations[buzz.destination_name].base_url,
    sameAs: [social.twitter, social.facebook, social.pinterest, social.youtube],
    logo,
  });
};

const getProductSchema = (buzz) => {
  let productList = [];
  const productsData = getAllProductsData(buzz);

  if (productsData !== []) {
    productsData.forEach((product, index) => {
      const productSchema =
        {
          "@type": "ListItem",
          position: index,
          item: {
            "@type": "Product",
            name: product.title,
            image: [
              product.img_url
            ],
            description: product.description,
            sku: product.sku,
            mpn: product.mpn,
            manufacturer: product.manufacturer,
            model: product.model,
            brand: {
              "@type": "Brand",
              name: product.brand
            },
            offers: {
              "@type": "Offer",
              price: product.list_price,
              priceCurrency: product.currency_code
            }
          }
        };

      if (
        product.title &&
        product.list_price &&
        product.img_url?.startsWith('https') &&
        product.currency_code &&
        product.currency_code !== 'None'
      ) productList.push(productSchema);
    })
  }

  return JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "ItemList",
    itemListElement: productList,
  })
};

export const PageLevelSchemaOrg = ({ buzz }) => {
  return (
    <Head>
      {!buzz.isCommentsPage && <script
        key="page-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: getPageSchema(buzz) }}
      />}
      <script
        key="org-schema"
        type="application/ld+json"
        data-schema="Organization"
        dangerouslySetInnerHTML={{ __html: getOrgSchema(buzz) }}
      />
      {!buzz.isCommentsPage && <script
        key="product"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: getProductSchema(buzz) }}
      />}
    </Head>
  );
};
