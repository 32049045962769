const normaliseEdition = edition =>
  ({
    // Editions from X-BF-User-Edition
    'en-au': 'au',
    'pt-br': 'br',
    'en-ca': 'ca',
    'de-de': 'de',
    'es-es': 'es',
    'fr-fr': 'fr',
    'en-in': 'in',
    'ja-jp': 'jp',
    'es-mx': 'mx',
    'en-uk': 'uk',
    'en-us': 'us',
    'es-null': 'esp',
    es: 'esp',
    // Editions from Buzz classification
    UK: 'uk',
    US: 'us',
    Australia: 'au',
    Canada: 'ca',
    India: 'in',
    Germany: 'de',
    Espanol: 'esp',
    Spain: 'es',
    Mexico: 'mx',
    France: 'fr',
    Japan: 'jp',
    Brasil: 'br',
  }[edition]);

const languageFromEdition = edition =>
  ({
    au: 'en',
    br: 'pt',
    ca: 'en',
    de: 'de',
    es: 'es',
    esp: 'es',
    fr: 'fr',
    in: 'en',
    jp: 'ja',
    mx: 'es',
    uk: 'en',
    us: 'en',
  }[edition]);

module.exports = {
  languageFromEdition,
  normaliseEdition,
};
